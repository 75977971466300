<template>
  <div>
    <v-card>
      <v-card-title>Solicitudes de admisión</v-card-title>
      <v-row>
        <v-col>
          <v-simple-table dense fixed-header height="90vh">
            <template v-slot:default>
              <thead>
                <tr>
                  <th>#</th>
                  <th class="text-left">
                    Nombre completo del apoderado
                  </th>
                  <th class="text-left">
                    Número de celular del apoderado
                  </th>
                  <th class="text-left">
                    Correo del apoderado
                  </th>
                  <th class="text-left">
                    Nombre completo del estudiante
                  </th>
                  <th class="text-left">
                    DNI del estudiante
                  </th>
                  <th class="text-left">
                    Colegio de procedencia
                  </th>
                  <th class="text-left">
                    Grado y nivel al que postula
                  </th>
                  <th>Estado de atención</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in datos" :key="item.id">
                  <td>{{ index + 1 }}</td>
                  <td>
                    {{ item.nombretutor }} {{ item.paternotutor }}
                    {{ item.maternotutor }}
                  </td>
                  <td>{{ item.celulartutor }}</td>
                  <td>{{ item.correotutor }}</td>
                  <td>
                    {{ item.nombreestudiante }} {{ item.paternoestudiante }}
                    {{ item.maternoestudiante }}
                  </td>
                  <td>{{ item.dniestudiante }}</td>
                  <td>{{ item.colegio }}</td>
                  <td>{{ item.grado }} de {{ item.nivel }}</td>
                  <td>
                    <v-chip
                      :color="!item.atendido ? 'red' : 'green'"
                      small
                      outlined
                      @click="cambiarEstado(item.id, item.atendido)"
                      :disabled="sendstate"
                    >
                      {{ !item.atendido ? "Pendiente" : "Atendido" }}
                    </v-chip>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
import { db } from "../firebase";
import { collection, getDocs, doc, updateDoc } from "firebase/firestore";
export default {
  data: () => ({
    datos: [],
    sendstate: false,
  }),
  methods: {
    async getDataFromFirebase() {
      this.datos = [];
      let aux_arr = [];
      const querySnapshot = await getDocs(
        collection(db, "solicitudes-admision")
      );
      querySnapshot.forEach((doc) => {
        let aux = {};
        aux = doc.data();
        aux.id = doc.id;
        aux_arr.push(aux);
      });

      let atendido = aux_arr.filter((e) => e.atendido);
      let pendiente = aux_arr.filter((e) => !e.atendido);

      this.datos = pendiente.concat(atendido);
    },

    async cambiarEstado(id, estado) {
      this.sendstate = true;
      const dataRef = doc(db, "solicitudes-admision", id);
      await updateDoc(dataRef, {
        atendido: !estado,
      });
      this.getDataFromFirebase();
      this.sendstate = false;
    },
  },
  mounted() {
    this.getDataFromFirebase();
  },
};
</script>
