//import firebase from "firebase/compat/app";
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
const firebaseConfig = {
  apiKey: "AIzaSyBZphDbAIMRHSmKSXPOY7O2KpFpzpaE4uQ",
  authDomain: "inscripciones-admision-2022.firebaseapp.com",
  projectId: "inscripciones-admision-2022",
  storageBucket: "inscripciones-admision-2022.appspot.com",
  messagingSenderId: "394423187789",
  appId: "1:394423187789:web:36f4bf5073a7ec85f29f04",
};
// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);
console.log(firebaseApp._name);
export const db = getFirestore();
